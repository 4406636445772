const shopItems = [
    {
        drinkURL: "masala",
        drinkStatic: "/images/media/masala_img.png",
        drinkGIF: "/images/media/masala.gif",
        backgroundColor: "white",
        backgroundURL: "spices",
        drinkName: "MASALA",
        drinkFullName: "MASALA CHAAS",
        drinkCost: 19.99,
    }, {
        drinkURL: "pudhina",
        drinkStatic: "/images/media/pudina_img.png",
        drinkGIF: "/images/media/tkc_pudina.gif",
        backgroundColor: "green",
        backgroundURL: "pudina",
        drinkName: "PUDINA",
        drinkFullName: "PUDINA SPICY CHAAS",
        drinkCost: 19.99,
    }, {
        drinkURL: "peri_peri",
        drinkStatic: "/images/media/peri_peri_img.png",
        drinkGIF: "/images/media/peri_peri.gif",
        backgroundColor: "red",
        backgroundURL: "chilli",
        drinkName: "PERI-PERI",
        drinkFullName: "PERI-PERI CHAAS",
        drinkCost: 19.99,
    }, {
        drinkURL: "blueberry",
        drinkStatic: "/images/media/blue_img.png",
        drinkGIF: "/images/media/blueberry.gif",
        drinkName: "BLUEBERRY",
        drinkFullName: "BLUEBERRY LASSI",
        drinkCost: 19.99,
    }, {
        drinkURL: "punjabi",
        drinkStatic: "/images/media/punjabi_img.png",
        drinkGIF: "/images/media/punjabi.gif",
        drinkName: "PUNJABI",
        drinkFullName: "CLASSIC PUNJABI LASSI",
        drinkCost: 19.99,
    }, {
        drinkURL: "rose",
        drinkStatic: "/images/media/rose_img.png",
        drinkGIF: "/images/media/rose.gif",
        backgroundColor: "baby-pink",
        backgroundURL: "rose_flower",
        drinkName: "ROSE",
        drinkFullName: "ROSE LASSI",
        drinkCost: 19.99,
    }, {
        drinkURL: "mango",
        drinkStatic: "/images/media/mango_img.png",
        drinkGIF: "/images/media/mango.gif",
        backgroundColor: "yellow",
        backgroundURL: "aam",
        drinkName: "MANGO",
        drinkFullName: "MANGO LASSI",
        drinkCost: 19.99,
    }, {
        drinkURL: "cookie_cream",
        drinkStatic: "/images/media/cookie_img.png",
        drinkGIF: "/images/media/cookie.gif",
        backgroundColor: "red",
        backgroundURL: "chilli",
        drinkName: "COOKIES",
        drinkFullName: "COOKIES AND DREAM LASSI",
        drinkCost: 19.99,
    },
    // , {
    //     drinkURL: "gujarati",
    //     drinkStatic: "/images/media/pudina_img.png",
    //     drinkGIF: "/images/media/tkc_pudina.gif",
    //     backgroundColor: "yellow",
    //     backgroundURL: "spices",
    //     drinkName: "GUJARATI",
    //     drinkFullName: "GUJARATI CHAAS",
    //     drinkCost: 19.99,
    // },
];

export {
    shopItems,
};