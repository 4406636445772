// importing from react
import { useNavigate } from "react-router";
// importing stylesheet
import "../style/footer.css";

export default function Footer() {
    const navigate = useNavigate();
    return (
        <>
            <footer class="footer" id="footer">
                <div class="footer-sections">
                    <div class="foot-section">
                        <img
                            class="footer-logo"
                            src={
                                process.env.PUBLIC_URL +
                                "/images/whitelogo.png"
                            }
                            alt="khaas chaas logo"
                        />
                    </div>

                    <div class="foot-section">
                        <li class="heading yellow-text"><span class="foot-heading bobby">Home</span></li>
                        <li>
                            <a
                                href="#story"
                                className="cur"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}>
                                Our Story
                            </a>
                        </li>
                        <li>
                            <a
                                href="#campus-delivery"
                                className="cur"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate("/shop")
                                }}>
                                Campus Delivery
                            </a>
                        </li>
                        <li>
                            <a
                                href="http://maps.google.com"
                                className="cur"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}>
                                Naupada Branch
                            </a>
                        </li>
                        {/* <li><b onClick={() => { window.scrollTo(0, 0); navigate("/shop") }}>Merch</b></li> */}
                    </div>

                    <div class="foot-section">
                        <li class="heading yellow-text"><span class="foot-heading bobby">Shop</span></li>
                        <li><b className="cur" onClick={() => { window.scrollTo(0, 0); navigate("/shop") }}>Masala Chaas</b></li>
                        <li><b className="cur" onClick={() => { window.scrollTo(0, 0); navigate("/shop") }}>Spicy Chaas</b></li>
                        <li><b className="cur" onClick={() => { window.scrollTo(0, 0); navigate("/shop") }}>Classic Punjabi Lassi</b></li>
                        <li><b className="cur" onClick={() => { window.scrollTo(0, 0); navigate("/shop") }}>Bluberry Lassi</b></li>
                    </div>

                    <div class="foot-section">
                        <li class="heading yellow-text"><span class="foot-heading bobby">Company</span></li>
                        <li><a href="#story">About Us</a></li>
                        <li><b className="cur" onClick={() => { navigate("/community") }}>Community</b></li>
                        <li><b className="cur" onClick={() => { window.scrollTo(0, 0); navigate("/reviews"); }}>Review Lassi</b></li>
                        <li><b className="cur" onClick={() => { navigate("/campus-delivery#faq") }}>FAQ</b></li>
                    </div>

                    <div class="foot-section">
                        <li class="heading yellow-text"><span class="foot-heading bobby">Social</span></li>
                        <li><b className="cur" onClick={() => { navigate("/instagram") }}>Instagram</b></li>
                        <li><b className="cur" onClick={() => { navigate("/youtube") }}>Youtube</b></li>
                        <li><b className="cur" onClick={() => { navigate("/facebook") }}>Facebook</b></li>
                        <li><b className="cur" onClick={() => { navigate("/twitter") }}>Twitter</b></li>
                    </div>
                </div>

                {/* <hr className="footer-hr" /> */}

                <div class="below-footer-links">
                    <div class="below-footer-section privacy-policy">
                        <li><a href="#"><span class="yellow-text">Terms</span></a></li>
                        <li><a href="#"><span class="yellow-text">Privacy</span></a></li>
                        <li><a href="#"><span class="yellow-text">Policy</span></a></li>
                    </div>

                    <div class="below-footer-section gap30 below-footer-brands">
                        <a target="_blank" href="https://instagram.com/thekhaaschaas/"><i class="fa-brands fa-instagram"></i></a>
                        <a target="_blank" href=""><i class="fa-brands fa-youtube"></i></a>
                        <a target="_blank" href=""><i class="fa-brands fa-twitter"></i></a>
                        <a target="_blank" href="https://www.linkedin.com/company/khaas-chaas/"><i class="fa-brands fa-linkedin"></i></a>
                    </div>

                    <div class="below-footer-section yellow-text">
                        <span class="yellow-text">@{new Date().getFullYear()} TheKhaasChaas&nbsp;|&nbsp;All Rights Reserved</span>
                    </div>
                </div>

                <div className="valueye-section">
                    <h5 className="valueye-heading">
                        Developed by:
                    </h5>
                    <a target="_blank" href="https://www.valueye.in">
                        <img
                            class="valueye-logo"
                            src={
                                process.env.PUBLIC_URL +
                                "/images/valueye-name.svg"
                            }
                            alt="valueye logo"
                        />
                    </a>
                </div>
            </footer>
        </>
    );
};
